<template>
  <redness-detail-template
    :is-duration-visible="isDurationVisible"
    :redness-discomfort-frequency="rednessDiscomfortFrequency"
    :redness-duration="rednessDuration"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import RednessDetailTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-redness/redness-detail/RednessDetailTemplate';

import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { REDNESS_DISCOMFORT_FREQUENCY } from '@/modules/questionnaire/api/constants';

const { requiredField } = fieldBuilder;

const FIELDS = [requiredField('rednessDiscomfortFrequency'), requiredField('rednessDuration')];

export default {
  name: 'RednessDetail',
  components: {
    RednessDetailTemplate
  },
  mixins: [makeStep(FIELDS)],
  computed: {
    isDurationVisible() {
      return this.rednessDiscomfortFrequency === 'all_the_time';
    }
  },
  watch: {
    rednessDiscomfortFrequency(newValue) {
      if (newValue !== REDNESS_DISCOMFORT_FREQUENCY.ALL_THE_TIME) {
        this.showNextStep();
      } else {
        this.scrollTo('#redness-duration');
      }
    },
    rednessDuration() {
      this.showNextStep();
    }
  },
  methods: {
    isFieldVisible(fieldName) {
      if (fieldName === 'rednessDuration') {
        return this.isDurationVisible;
      }

      return true;
    }
  }
};
</script>
